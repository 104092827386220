body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.david {
  font-family: 'David Libre', serif;
}

.fill {
  text-align: justify;
}

.fill::after {
  content: "";
  display: inline-block;
  width: 100%;
}

/* ol { counter-reset: item } */
/* li{ display: block } */
/* li:before { content: counters(item, ".") " "; counter-increment: item } */
li {
  list-style-position: inside;
}

.fixed-size {
  font-size: 12px;
}

input[type=text] {
  border: none;
  border-bottom: 2px solid black;
}

/* Reset default styles */
select {
  appearance: none;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 16px;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  
}

/* Custom style for the arrow icon */
select::-ms-expand {
  display: none;
}

select::after {
  content: "\f078";
  font-family: FontAwesome; /* If you are using Font Awesome icons */
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Optional hover and focus styles for better user experience */
select:hover {
  border-color: #a0a0a0;
}

select:focus {
  border-color: #4d90fe;
  box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.2);
}